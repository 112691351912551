export const FormSet = {
  inputs: Symbol('formset.inputs'),
  basic: Symbol('formset.basic'),
  prepress: Symbol('formset.prepress'),
  distribution: Symbol('formset.distribution'),
  press: {
    formset: Symbol('formset.press.formset'),
    body: Symbol('formset.press.body'),
    bookbinding: Symbol('formset.press.bookbinding'),
    cover: Symbol('formset.press.cover'),
    coverbelt: Symbol('formset.press.coverbelt'),
    frontcover: Symbol('formset.press.frontcover'),
    hakuoshi: Symbol('formset.press.hakuoshi'),
    mikaeshi: Symbol('formset.press.mikaeshi'),
  },
  converter: {
    press: {
      body: Symbol('formset.converter.press.body'),
      cover: Symbol('formset.converter.press.cover'),
      coverbelt: Symbol('formset.converter.press.coverbelt'),
      frontcover: Symbol('formset.converter.press.frontcover'),
      mikaeshi: Symbol('formset.converter.press.mikaeshi'),
    },
  },
};
