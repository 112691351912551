import * as Table from '../../table/b/structs';
import { GenreID } from '../../table/a/structs';
import * as Searcher from '../../table/b/unitpricesearcher';

export class BookBindingCalcurator {
  private hardFee: Searcher.HangataRangeSearcher;
  private softFee: Searcher.HangataRangeSearcher;
  private pictureFee: Searcher.HangataRangeSearcher;

  public constructor(
    hardFee: Searcher.HangataRangeSearcher,
    softFee: Searcher.HangataRangeSearcher,
    pictureFee: Searcher.HangataRangeSearcher
  ) {
    this.hardFee = hardFee;
    this.softFee = softFee;
    this.pictureFee = pictureFee;
  }

  public execute(
    hangataId: string,
    genreId: string,
    bindingType: Table.BindingType,
    copies: number,
    pages: number
  ): number {
    let searcher: Searcher.HangataRangeSearcher;
    if (genreId === GenreID.PictureBook) {
      searcher = this.pictureFee;
    } else if (bindingType === Table.BindingType.hard) {
      searcher = this.hardFee;
    } else if (bindingType === Table.BindingType.soft) {
      searcher = this.softFee;
    } else {
      throw new Error('invalid type');
    }

    let target: Searcher.CopyPageRangeCompareSet = {
      hangataId: hangataId,
      copies: copies,
      pages: pages,
    };
    let unit = searcher.searchUnit(target);
    return unit.value;
  }
}
