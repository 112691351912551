import * as FileData from '../structs';
import * as Table from '../../structs';
import { DataFileConverter } from '../../../datafileconverter';
import { RangeParser } from '../../../parser/range';

export class HangataCopyPageUnitConverter
  implements DataFileConverter<Table.HangataCopyPageUnitPrice[]> {
  readonly fileDataIdList: FileData.DataType[] = [
    FileData.DataType.BookBindingEhon,
    FileData.DataType.BookBindingHeisei,
    FileData.DataType.BookBindingJyousei,
  ];

  public hasType(type: string): boolean {
    for (const id of this.fileDataIdList) {
      if (type === id) {
        return true;
      }
    }

    return false;
  }

  public convert(parsed_data: any): Table.HangataCopyPageUnitPrice[] {
    if (
      typeof parsed_data.type === 'undefined' ||
      this.hasType(parsed_data.type) === false
    ) {
      throw new Error('invalid type');
    } else if (
      typeof parsed_data.values === 'undefined' ||
      typeof parsed_data.values[0].copies === 'undefined' ||
      typeof parsed_data.values[0].pages === 'undefined'
    ) {
      throw new Error('invalid type');
    }

    let result: Table.HangataCopyPageUnitPrice[] = [];
    let data = parsed_data as FileData.CopyPageFee;
    const range_parser = new RangeParser();

    for (let unit of data.values) {
      let row: Table.HangataCopyPageUnitPrice = {
        hangataId: data.hangataId,
        copies: range_parser.parse(unit.copies),
        pages: range_parser.parse(unit.pages),
        value: unit.value,
      };
      result.push(row);
    }

    return result;
  }
}
