import { IRegister } from './iregister';
import { Container } from '../container';
import { TYPES } from '../types';
import { PrepressTables } from '../../prepress/subtotal';
import { PressTables } from '../../press/subtotal/presssubtotal';
import { BodyTables } from '../../press/subtotal/body';
import { BookBindingTables } from '../../press/subtotal/bookbinding';
import { CoverTables } from '../../press/subtotal/cover';
import { CoverBeltTables } from '../../press/subtotal/coverbelt';
import { FrontCoverTables } from '../../press/subtotal/frontcover';
import { HakuoshiTables } from '../../press/subtotal/hakuoshi';
import { MikaeshiTables } from '../../press/subtotal/mikaeshi';

export class Table implements IRegister {
  public register(container: Container) {
    container.instance(TYPES.Table.prepress, (c: Container) => {
      const table: PrepressTables = {
        planning: c.make(TYPES.Searcher.planning),
        editing: c.make(TYPES.Searcher.editing),
        proofreading: c.make(TYPES.Searcher.proofreading),
        writing: c.make(TYPES.Searcher.writing),
        rewriting: c.make(TYPES.Searcher.rewriting),
        coverdesign: c.make(TYPES.Searcher.coverdesign),
        frontcoverdesign: c.make(TYPES.Searcher.frontcoverdesign),
        mikaeshidesign: c.make(TYPES.Searcher.mikaeshidesign),
        coverbeltdesign: c.make(TYPES.Searcher.coverbeltdesign),
        dtp: c.make(TYPES.Searcher.dtp),
      };
      return table;
    });

    container.instance(TYPES.Table.press.table, (c: Container) => {
      const table: PressTables = {
        body: c.make(TYPES.Table.press.body),
        bookbinding: c.make(TYPES.Table.press.bookbinding),
        cover: c.make(TYPES.Table.press.cover),
        coverbelt: c.make(TYPES.Table.press.coverbelt),
        frontcover: c.make(TYPES.Table.press.frontcover),
        hakuoshi: c.make(TYPES.Table.press.hakuoshi),
        mikaeshi: c.make(TYPES.Table.press.mikaeshi),
      };
      return table;
    });

    container.instance(TYPES.Table.press.body, (c: Container) => {
      const table: BodyTables = {
        bodyPrintingPlate: c.make(TYPES.Searcher.body.printingPlate),
        bodyPrinting: c.make(TYPES.Searcher.body.printing),
        bodyProofreadingMono: c.make(TYPES.Searcher.body.proofreading.mono),
        bodyProofreadingColor: c.make(TYPES.Searcher.body.proofreading.color),
        bodyPaper: c.make(TYPES.Searcher.master.paperinfo),
      };
      return table;
    });

    container.instance(TYPES.Table.press.bookbinding, (c: Container) => {
      const table: BookBindingTables = {
        hard: c.make(TYPES.Searcher.bookbinding.hard),
        soft: c.make(TYPES.Searcher.bookbinding.soft),
        picture: c.make(TYPES.Searcher.bookbinding.picture),
      };
      return table;
    });

    container.instance(TYPES.Table.press.cover, (c: Container) => {
      const table: CoverTables = {
        coverPrintingPlateVarnish: c.make(
          TYPES.Searcher.cover.printingPlate.varnish
        ),
        coverPrintingPlatePP: c.make(TYPES.Searcher.cover.printingPlate.pp),
        coverPrinting: c.make(TYPES.Searcher.cover.printing),
        coverPP: c.make(TYPES.Searcher.cover.printingPP),
        coverProofreading: c.make(TYPES.Searcher.cover.proofreading),
        coverPaper: c.make(TYPES.Searcher.master.paperinfo),
      };
      return table;
    });

    container.instance(TYPES.Table.press.coverbelt, (c: Container) => {
      const table: CoverBeltTables = {
        printingPlateTokushoku: c.make(
          TYPES.Searcher.coverbelt.printingPlate.tokushoku
        ),
        printingPlateColor: c.make(
          TYPES.Searcher.coverbelt.printingPlate.color
        ),
        printingTokushoku: c.make(TYPES.Searcher.coverbelt.printingTokushoku),
        printingColor: c.make(TYPES.Searcher.coverbelt.printingColor),
        printingPP: c.make(TYPES.Searcher.coverbelt.printingPP),
        proofReading: c.make(TYPES.Searcher.coverbelt.proofReading),
        paper: c.make(TYPES.Searcher.master.paperinfo),
      };
      return table;
    });

    container.instance(TYPES.Table.press.frontcover, (c: Container) => {
      const table: FrontCoverTables = {
        printingPlateTokushoku: c.make(
          TYPES.Searcher.frontcover.printingPlate.tokushoku
        ),
        printingPlateColor: c.make(
          TYPES.Searcher.frontcover.printingPlate.color
        ),
        printingTokushoku: c.make(TYPES.Searcher.frontcover.printingTokushoku),
        printingColor: c.make(TYPES.Searcher.frontcover.printingColor),
        printingPP: c.make(TYPES.Searcher.frontcover.printingPP),
        proofReading: c.make(TYPES.Searcher.frontcover.proofReading),
        paper: c.make(TYPES.Searcher.master.paperinfo),
      };
      return table;
    });

    container.instance(TYPES.Table.press.hakuoshi, (c: Container) => {
      const table: HakuoshiTables = {
        hakuoshiPrintingPlate: c.make(TYPES.Searcher.hakuoshi.printingPlate),
        hakuoshiPress: c.make(TYPES.Searcher.hakuoshi.press),
      };
      return table;
    });

    container.instance(TYPES.Table.press.mikaeshi, (c: Container) => {
      const table: MikaeshiTables = {
        printingPlate: c.make(TYPES.Searcher.mikaeshi.printingPlate),
        printing: c.make(TYPES.Searcher.mikaeshi.printing),
        proofreading: c.make(TYPES.Searcher.mikaeshi.proofreading),
        paper: c.make(TYPES.Searcher.master.paperinfo),
      };
      return table;
    });
  }
}
