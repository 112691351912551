export interface PrimaryGenre {
  genreId: string;
  type: string;
}

export interface IdFeeTable extends PrimaryGenre {
  value: number;
}

export interface CopyRangeFee extends PrimaryGenre {
  values: CopyUnit[];
}

export interface CopyUnit {
  copies: string;
  value: number;
}

export interface GradeFee extends PrimaryGenre {
  values: GradeUnit[];
}

export interface GradeUnit {
  grade: string;
  value: number;
}

export enum GradeType {
  Simple = 'simple',
  Standard = 'standard',
}

export enum DataType {
  Basic = 'basic',
  Kikaku = 'kikaku',
  Henshu = 'henshu',
  Kousei = 'kousei',
  Writng = 'writing',
  Rewrite = 'rewrite',
  CoverDesign = 'coverdesign',
  FrontCoverDesign = 'frontcoverdesign',
  MikaeshiDesign = 'mikaeshidesign',
  CoverBeltdesign = 'coverbeltdesign',
  Dtp = 'dtp',
  RyutsuBasic = 'ryutsubasic',
  ShotenItaku = 'shotenitaku',
  RyutsuSales = 'ryutsusales',
  RyutsuAmazon = 'ryutsuamazon',
}
