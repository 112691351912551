import { IRegister } from './iregister';
import { Container } from '../container';
import { TYPES } from '../types';
import { BasicFormsetConverter } from '../../formset/converters/basic';
import { DistributionFormsetConverter } from '../../formset/converters/distribution';
import { PrepressFormsetConverter } from '../../formset/converters/prepress';
import { PressBookBingingFormsetConverter } from '../../formset/converters/pressbookbinding';
import { PressHakuoshiFormsetConverter } from '../../formset/converters/presshakuoshi';
import { PressFormSet } from '../../press/subtotal/presssubtotal';

export class FormSet implements IRegister {
  public register(container: Container) {
    container.instance(TYPES.FormSet.basic, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = new BasicFormsetConverter();
      const formset = conv.convert(inputs);
      return formset;
    });

    container.instance(TYPES.FormSet.distribution, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = new DistributionFormsetConverter();
      const formset = conv.convert(inputs);
      return formset;
    });

    container.instance(TYPES.FormSet.prepress, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = new PrepressFormsetConverter();
      const formset = conv.convert(inputs);
      return formset;
    });

    container.instance(TYPES.FormSet.press.formset, (c: Container) => {
      const formset: PressFormSet = {
        body: c.make(TYPES.FormSet.press.body),
        bookbinding: c.make(TYPES.FormSet.press.bookbinding),
        cover: c.make(TYPES.FormSet.press.cover),
        coverbelt: c.make(TYPES.FormSet.press.coverbelt),
        frontcover: c.make(TYPES.FormSet.press.frontcover),
        hakuoshi: c.make(TYPES.FormSet.press.hakuoshi),
        mikaeshi: c.make(TYPES.FormSet.press.mikaeshi),
      };
      return formset;
    });

    container.instance(TYPES.FormSet.press.body, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = c.make(TYPES.FormSet.converter.press.body);
      const formset = conv.convert(inputs);
      return formset;
    });

    container.instance(TYPES.FormSet.press.bookbinding, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = new PressBookBingingFormsetConverter();
      const formset = conv.convert(inputs);
      return formset;
    });

    container.instance(TYPES.FormSet.press.cover, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = c.make(TYPES.FormSet.converter.press.cover);
      const formset = conv.convert(inputs);
      return formset;
    });

    container.instance(TYPES.FormSet.press.coverbelt, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = c.make(TYPES.FormSet.converter.press.coverbelt);
      const formset = conv.convert(inputs);
      return formset;
    });

    container.instance(TYPES.FormSet.press.frontcover, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = c.make(TYPES.FormSet.converter.press.frontcover);
      const formset = conv.convert(inputs);
      return formset;
    });

    container.instance(TYPES.FormSet.press.hakuoshi, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = new PressHakuoshiFormsetConverter();
      const formset = conv.convert(inputs);
      return formset;
    });

    container.instance(TYPES.FormSet.press.mikaeshi, (c: Container) => {
      const inputs = c.make(TYPES.FormSet.inputs);
      const conv = c.make(TYPES.FormSet.converter.press.mikaeshi);
      const formset = conv.convert(inputs);
      return formset;
    });
  }
}
