export interface InputParams {
  // Basic
  genreId: string;
  hangataId: string;
  bookbindingId: string;
  pageCount: number;
  copies: number;
  textcolor: string;
  // Printing
  hasBookcover: boolean;
  bookcoverPaperId: number;
  hasCoverBelt: boolean;
  coverbeltPaperId: number;
  frontcoverPaperId: number;
  isPP: boolean;
  bodyPaperId: number;
  hasMikaeshi: boolean;
  mikaeshiPaperId: number;
  isHakuoshi: boolean;
  // Prepress
  planingGrade: string;
  editingGrade: string;
  proofreadingGrade: string;
  bindingGrade: string;
  isWriting: boolean;
  isRewriting: boolean;
  // Sales
  distributionPlan: string;
  promotionPlan: string;
}

export enum BookbindingId {
  Soft = 'soft',
  Hard = 'hard',
}

export enum TextColor {
  Color = 'color',
  Mono = 'mono',
}

export enum DistributionPlanId {
  None = '',
  CosignmentBookStore = 'cosign',
  CosignmentWithSales = 'cosignwithsales',
  Amazon = 'amazon',
  BookStore = 'bookstore',
}

export enum GradeTypeId {
  None = '',
  Simple = 'simple',
  Standard = 'standard', //本格的
}
