import * as FileData from '../structs';
import * as Table from '../../structs';
import { DataFileConverter } from '../../../datafileconverter';

export class GenreUnitConverter
  implements DataFileConverter<Table.GenreUnitPrice> {
  readonly fileDataIdList: FileData.DataType[] = [
    FileData.DataType.Writng,
    FileData.DataType.Rewrite,
    FileData.DataType.MikaeshiDesign,
    FileData.DataType.CoverBeltdesign,
    FileData.DataType.Dtp,
    FileData.DataType.RyutsuBasic,
    FileData.DataType.ShotenItaku,
    FileData.DataType.RyutsuSales,
    FileData.DataType.RyutsuAmazon,
  ];

  public hasType(type: string): boolean {
    for (const id of this.fileDataIdList) {
      if (type === id) {
        return true;
      }
    }

    return false;
  }

  public convert(parsed_data: any): Table.GenreUnitPrice {
    if (
      typeof parsed_data.type === 'undefined' ||
      this.hasType(parsed_data.type) === false
    ) {
      throw new Error('invalid type');
    } else if (typeof parsed_data.value === 'undefined') {
      throw new Error('invalid type');
    }

    let data = parsed_data as FileData.IdFeeTable;
    let result: Table.GenreUnitPrice = {
      genreId: data.genreId,
      value: data.value,
    };

    return result;
  }
}
