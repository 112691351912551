import { IRegister } from '../iregister';
import { Container } from '../../container';
import { TYPES } from '../../types';
import * as Searcher from '../../../table/a/unitpricesearcher';
import { DataType } from '../../../table/a/datafile/structs';

export class Genre implements IRegister {
  readonly unitpriceKeys = [
    { key: DataType.Writng, registerKey: TYPES.Searcher.writing },
    { key: DataType.Rewrite, registerKey: TYPES.Searcher.rewriting },
    {
      key: DataType.MikaeshiDesign,
      registerKey: TYPES.Searcher.mikaeshidesign,
    },
    {
      key: DataType.CoverBeltdesign,
      registerKey: TYPES.Searcher.coverbeltdesign,
    },
    { key: DataType.Dtp, registerKey: TYPES.Searcher.dtp },
    {
      key: DataType.RyutsuBasic,
      registerKey: TYPES.Searcher.distribution.basic,
    },
    {
      key: DataType.ShotenItaku,
      registerKey: TYPES.Searcher.distribution.cosignment,
    },
    {
      key: DataType.RyutsuSales,
      registerKey: TYPES.Searcher.distribution.sales,
    },
    {
      key: DataType.RyutsuAmazon,
      registerKey: TYPES.Searcher.distribution.amazon,
    },
  ];

  public register(container: Container) {
    for (const unitpriceKey of this.unitpriceKeys) {
      container.singleton(unitpriceKey.registerKey, (c: Container) => {
        const unitprices = c.make(TYPES.UnitPrice.table.a) as Map<
          string,
          any[]
        >;
        const dataKey = unitpriceKey.key;
        const unitprice = unitprices.get(dataKey);
        if (typeof unitprice === 'undefined') {
          throw new Error('Not Exists Data: ' + dataKey);
        }

        const result = new Searcher.GenreSearcher(
          unitprice,
          Searcher.GenreComparetor
        );
        return result;
      });
    }
  }
}
